<template>
  <div>
    <a-modal
      v-model="visibleModalManageProgram"
      class="mod"
      :title="status.isEdit ? 'Form Edit Program' : 'Form Create Program'"
      @cancel="toggleModalManageProgram"
      centered
    >
      <form-program :dataProgram="newProgram" @handle-change="handleChange" />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageProgram">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageProgram"
          @click="handleOkModalManageProgram"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManageProgram('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Study Program</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="programColumns"
        :data-source="programDataTable"
        :pagination="programPagination"
        :loading="programLoadingTable"
        bordered
      >
        <div slot="action" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModalManageProgram('edit', record)"
            class="mr-3 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            @click.prevent="handleDeleteProgram(record)"
            class="text-danger border border-danger"
            size="large"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const formProgram = () => import('@/components/app/Admin/Master/Form/Program')

const programColumns = [
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  name: 'Program',
  components: {
    formProgram,
  },
  data() {
    return {
      programColumns,
      visibleModalManageProgram: false,
      loadingActionModalManageProgram: false,
      loadingDeleteProgram: false,
      programLoadingTable: false,
      programDataTable: [],
      programPagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newProgram: {
        nama: null,
      },
      idEdit: null,
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      console.log('parent', payload)
      this.newProgram[column] = value
    },
    toggleModalManageProgram(status, data) {
      this.visibleModalManageProgram = !this.visibleModalManageProgram
      if (!this.visibleModalManageProgram) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newProgram = {
            nama: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newProgram = {
            nama: data.nama,
          }
        }
      }
    },
    handleOkModalManageProgram() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this study program?' : 'Are you sure want to create new study program'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageProgram = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataProgram: this.newProgram,
            } : {
              dataProgram: this.newProgram,
            }
            this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_PROGRAM`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageProgram = false
                this.toggleModalManageProgram()
                this.fetchDataProgram()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Study Program has been updated' : 'Study Program has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Study Program has not been updated' : 'Study Program has not been created'
                  this.$notification.error({
                    message: 'Failed',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleDeleteProgram(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this study program?</div>
        ),
        onOk: () => {
          this.programLoadingTable = true
          this.loadingDeleteProgram = true
          this.$store.dispatch('master/DELETE_PROGRAM', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataProgram()
              this.loadingDeleteProgram = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Study Program has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Failed',
                  description: 'Study Program has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeleteProgram = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    fetchDataProgram(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.programLoadingTable = true
      this.$store.dispatch('master/FETCH_PROGRAM', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.programLoadingTable = false
          // const pagination = { ...this.pagination }
          // pagination.total = res.total
          // this.pagination = pagination
          // console.log(res)
          this.programDataTable = res.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataProgram()
  },
  computed: {
    isValidateForm() {
      if (this.newProgram.nama) {
        return false
      }
      return true
    },
  },
}
</script>

<style>
</style>
